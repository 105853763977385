import React from "react";
import "./Statuses.css"

const Statuses = (props) => {
  if (props.status === 1) {
    return <div className="collect status">Collecting</div>;
  } else if (props.status === 2){
    return <div className="process status">Data processing</div>
  } else if (props.status === 3){
    return <div className="predict status">Predicting</div>
  } else if (props.status === 4){
    return <div className="done status">Done</div>
  } else {
    return <div className="error status">404</div>
  }
};

export default Statuses;
