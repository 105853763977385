import React from "react";
import { Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa"
// import * as AiIcons from "react-icons/ai"
import axios from "axios";
import useSWR from "swr";
import Statuses from "../components/Statuses";



const YoutubeHistory = () => {
  const fetcher = async () => {
    const response = await axios.get("https://be-sa.qoisoctava.com/progress/youtube");
    return response.data;
  }; 

  const { data, error} = useSWR("history", fetcher);
  if (error) return "An error has occurred.";
  if (!data) return <h2>Loading...</h2>;



  return (
    <div className="container">
      <div className="row my-4">
        <h1 className="display-4"> <FaIcons.FaYoutube/>  YouTube Prediction History</h1>
      </div>
      <div className="row">
        <table class="table">
          <thead className="bg-dark-subtle">
            <tr>
              <th scope="col">#</th>
              <th scope="col">Collected at</th>
              <th scope="col">Video Title </th>
              <th scope="col">Uploaded at</th>
              <th scope="col">Status</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {data.map((history, index)=>(
            <tr key={data.id}>
              <th scope="row">{index+1}</th>
              <td>{history.get_date}</td>
              <td>{history.title}</td>
              <td>{history.video_date}</td>
              <td >
              <Statuses status={history.status}/>
              </td>
              <td>
                <Link to={`/result/report/youtube/${history.id}`}className={history.status !== 4 ? "btn btn-subtle-dark mx-1 disabled " : "btn btn-success mx-1 "}> <FaIcons.FaArrowRight/></Link>
              </td>
            </tr>

            ))}
            
            
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default YoutubeHistory;
