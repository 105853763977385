
import React from 'react'


// import { Link } from 'react-router-dom'

function Home() {

  return (
    <div className="container">
      <div className="row mt-5 mb-2">
        <img src="/political.png" className='title-image' alt=""/>
        <h1 className='display-4'>Dasbor Analisis Sentimen Opini Politik Media Sosial</h1>
        <hr />
        <h1 className='display-5'> <i>Collect, Process and Analyze</i> </h1>
        {/* <h1 className='display-6'> Implementasi Model SVM Menggunakan TF-IDF dengan Statistical Feature</h1> */}
      </div>
    </div>
  )
}

export default Home