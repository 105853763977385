import React from 'react'
import { Doughnut } from 'react-chartjs-2'

// eslint-disable-next-line
import { Chart as ChartJS } from 'chart.js/auto'

function DoughnutChart({chartData}) {
  const config = {
    layout : [{
        autoPadding: false
    }],
    maintainAspectRatio: false
  }
  return <>
  
  <Doughnut data={chartData} options={config}/>

  </>
}

export default DoughnutChart 