import React, { useState, useEffect } from "react";
// import { HariHari } from "../components/Data";
import LineChart from "../components/LineChart";
import YtCommentCard from "../components/YtCommentCard";
import * as FaIcons from "react-icons/fa";
import "./Report.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import DoughnutChart from "../components/DoughnutChart";

function YoutubeReportByHistory() {
  // Ambil data dummy
  const {id} = useParams()
  // console.log(keyword,startdate,enddate)
  let [countData, setCountData] = useState([]);
  let [lineData, setLineData] = useState([]);
  let [tweetData, setTweetData] = useState([]);
  let [infoData, setInfoData] = useState([]);

  // The useEffect() hook fires any time that the component is rendered.
  // An empty array is passed as the second argument so that the effect only fires once.
  useEffect(() => {
    axios
      .get(`https://be-sa.qoisoctava.com/data/youtube/${id}/count`)
      .then((response) => {
        console.log(response.data);
        setCountData(response.data);
      });
    axios
      .get(`https://be-sa.qoisoctava.com/data/youtube/${id}/summary`)
      .then((response) => {
        // console.log(response.data);
        setLineData(response.data);
      });
    axios.get(`https://be-sa.qoisoctava.com/data/youtube/${id}`).then((response) => {
      // console.log(response.data);
      setTweetData(response.data);
    });
    axios.get(`https://be-sa.qoisoctava.com/progress/youtube/${id}`).then((response) => {
      // console.log(response.data);
      setInfoData(response.data);
    });
    
  }, [id]);

  console.log(countData)
  const DoughnutData = {
    labels: [
      'Negatif',
      'Positif',
      'Netral'
    ],
    datasets: [{
      label: 'Sentimen',
      data: [countData.map((data) => data.negative),
        countData.map((data) => data.positive),
        countData.map((data) => data.neutral)],
      backgroundColor: [
        'rgba(220,53,69,1)',
        'rgba(13,110,253,1)',
        'rgba(255,193,7,1)'
      ],
      hoverOffset: 4
    }]
  };

  const LineData = {
    labels: lineData.map((data) => data.commentDate),
    datasets: [
      {
        label: "Positif",
        data: lineData.map((data) => data.positive),
        borderColor: ["rgba(13,110,253,1)"],
        backgroundColor: ["rgba(13,110,253,1)"],
      },
      {
        label: "Netral",
        data: lineData.map((data) => data.neutral),
        backgroundColor: ["rgba(255,193,7,1)"],
        borderColor: ["rgba(255,193,7,1)"],
      },
      {
        label: "Negatif",
        data: lineData.map((data) => data.negative),
        backgroundColor: ["rgba(220,53,69,1)"],
        borderColor: ["rgba(220,53,69,1)"],
      },
    ],
  };
  return (
    <div className="container">
      {/* Section Total */}
      <div className="row row-cols-sm-2 row-cols-xl-4 row my-2 ">
        <div className="col my-2">
          <div className="col align-self-center">
            <h1 className="fw-bold display-2 mt-2 text-black">{countData.map((data) => data.total)}</h1>
            <h1 className="display-6 text-black">KOMENTAR</h1>
          </div>
        </div>
        <div className="col my-2">
          <div className="card bg-primary ">
            <div className="col align-self-center">
              <h1 className="fw-bold display-2 mt-2 text-white">{countData.map((data) => data.positive)}</h1>
              <h1 className="display-6 text-white">POSITIF</h1>
            </div>
          </div>
        </div>
        <div className="col my-2">
          <div className="card bg-warning ">
            <div className="col align-self-center">
              <h1 className="fw-bold display-2 mt-2 text-white">{countData.map((data) => data.neutral)}</h1>
              <h1 className="display-6 text-white">NETRAL</h1>
            </div>
          </div>
        </div>
        <div className="col my-2">
          <div className="card bg-danger ">
            <div className="col align-self-center">
              <h1 className="fw-bold display-2 mt-2 text-white">{countData.map((data) => data.negative)}</h1>
              <h1 className="display-6 text-white">NEGATIF</h1>
            </div>
          </div>
        </div>
      </div>
        {/* Info Data Section */}
        <div className="row justify-content-center align-items-center my-5">
        
        <div className="col-7">
<div className="row text-center">
  <p className="display-6">Hasil<b> Analisis Sentimen </b></p>
  <hr className="m-0" />
  <p>diproses pada <b> {infoData.map((data)=> data.dateGet)}</b></p>
</div>
<div className="row">
  <p className="display-6"><b>Kanal : </b> "{infoData.map((data)=> data.channel_name)}" </p>
</div>
<div className="row">
  <p className="display-6"><b>Judul Video: </b> "{infoData.map((data)=> data.title)}" </p>
</div>
<div className="row">
  <p className="display-6"><b>Tanggal Diunggah : </b> "{infoData.map((data)=> data.videoDate)}" </p>
</div>
        </div>
        <div className="col-5">
          <div className="infoDataSection mx-2 ">
        <DoughnutChart chartData={DoughnutData} />
          </div>
        </div>
      </div>
      {/* Line Chart Section */}
      <div className="row d-flex justify-content-center  shadow rounded-2 bg-white my-2 chart-wrapper">
        <div className="col ">
          <LineChart chartData={LineData} />
        </div>
      </div>
      <div className="row">{/* Space buat wordcloud kalau jadi */}</div>
  
      {/* Tweets Section */}
      <div className="row shadow rounded-2 bg-white my-2 ">
        <div className="row mt-2 ms-auto">
          <div className="h5 m-title px-5 py-2 shadow-sm">
            <FaIcons.FaYoutube /> Comments
          </div>
        </div>
        <div className="row pb-2 ">
          <body className="overflow-scroll space ">
            <ul >
              <li className="list">
                {tweetData.map((data) => (
                  <YtCommentCard
                    username={data.commentator}
                    content={data.content}
                    date={data.comment_date}
                    like={data.like_count}
                    sentiment={data.sentiment}
                  />
                ))}
              </li>
            </ul>
          </body>
        </div>
      </div>
    </div>
  );
}

export default YoutubeReportByHistory;
