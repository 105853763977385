import React from 'react'
import { Line } from 'react-chartjs-2'

// eslint-disable-next-line
import { Chart as ChartJS } from 'chart.js/auto'

function LineChart({chartData}) {

  const config = {
    aspectRatio : 1|3
  }
  return <>
  
  <Line data={chartData} options={config}/>

  </>
}

export default LineChart 