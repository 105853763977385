import React from "react";
import "./TweetCard.css";

import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";

const YtCommentCard = (props) => {

  if(props.sentiment === 'Positive'){
    return (
      <div className="row mb-2 rounded-2 shadow-sm bg-light">
        <div className="col">
          <div className="row fw-bold mt-2 mx-2">
          {props.username}
          </div>
          <div className="row mb-2 mx-2">
          {props.content}
          </div>
          <hr className="my-0 border-2 border-info"/>
          <div className="row container my-2">
            <div className="col">
              <AiIcons.AiTwotoneCalendar/> {props.date}
            </div>
            <div className="col">
              <FaIcons.FaThumbsUp/> {props.like}
            </div>
          </div>
          
        </div>
        <div className="col bg-primary border border-primary py-1 sentimen"></div>
      </div>
    );
  } else if(props.sentiment === 'Neutral') {
    return (
      <div className="row mb-2 rounded-2 shadow-sm bg-light">
        <div className="col">
          <div className="row fw-bold mt-2 mx-2">
          {props.username}
          </div>
          <div className="row mb-2 mx-2">
          {props.content}
          </div>
          <hr className="my-0 border-2 border-warning"/>
          <div className="row container my-2">
          <div className="col">
              <AiIcons.AiTwotoneCalendar/> {props.date}
            </div>
            <div className="col">
              <FaIcons.FaThumbsUp/> {props.like}
            </div>
          </div>
        </div>
        <div className="col bg-warning border border-warning py-1 sentimen"> </div>
      </div>
    );
  } else if(props.sentiment === 'Negative'){
    return (
      <div className="row mb-2 rounded-2 shadow-sm bg-light">
        <div className="col">
          
          <div className="row fw-bold mt-2 mx-2">
          {props.username}
          </div>
          <div className="row mb-2 mx-2">
          {props.content}
          </div>
          <hr className="my-0 border-2 border-danger"/>
          <div className="row container my-2">
          <div className="col">
              <AiIcons.AiTwotoneCalendar/> {props.date}
            </div>
            <div className="col">
              <FaIcons.FaThumbsUp/> {props.like}
            </div>
          </div>
        </div>
        <div className="col bg-danger border border-danger py-1 sentimen"> </div>
      </div>
    );
  } else {
    return (
      <div className="row">404 not found</div>
    )
  }
};

export default YtCommentCard;
