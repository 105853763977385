import React, { useState } from "react";
import "./PredictForm.css";
import axios from "axios";
import * as AiIcons from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const NewTwitterPrediction = () => {
  // let [postTopics, setPostTopics] = useState([]);
  // useEffect(() => {
  //   axios.get(`http://localhost:3000/data/topics`).then((response) => {
  //     setPostTopics(response.data);
  //   });
  // });

  const [keyword, setKeyword] = useState("");
  const [dateSince, setDateSince] = useState("");
  const [dateUntil, setDateUntil] = useState("");
  const navigate = useNavigate();

  const Predict = async (e) => {
    e.preventDefault();
    axios.post("https://api-sa.qoisoctava.com/twitter/prediction/", {
      keyword: keyword,
      dateSince: dateSince,
      dateUntil: dateUntil,
      topic: 'Politic'
    });
    navigate("/result/history/Twitter");
  };

  return (
    <div className="container my-auto ">
      <div className="wrapper-form fadeInDown">
        <div id="formContent">
          <div className="fadeIn first">
            <h1 className="pt-3">
              <AiIcons.AiOutlineTwitter />
            </h1>
            <h1 className="display-6">Buat Prediksi</h1>
          </div>

          <form onSubmit={Predict}>
             {/* Keyword */}
            <div>
              <input
                type="text"
                id="login"
                className="fadeIn second"
                name="login"
                placeholder="Keyword Pencarian"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
              />
            </div>
            {/* Rentang Tanggal Awal Tweet */}
            <div className="fadeIn third date">
              <p className="my-1 text-dark-subtle">
                Rentang Tanggal Awal Tweet
              </p>
              <input
                type="date"
                id="login"
                className="mt-0"
                name="login"
                data-format="0000-00-00"
                placeholder="YYYY-MM-DD"
                value={dateSince}
                onChange={(e) => setDateSince(e.target.value)}
              />
            </div>
            <div className="fadeIn third date">
              <p className="my-1 text-dark-subtle">
                Rentang Tanggal Akhir Tweet
              </p>
              <input
                type="date"
                id="login"
                className="mt-0"
                name="login"
                data-format="0000-00-00"
                placeholder="YYYY-MM-DD"
                value={dateUntil}
                onChange={(e) => setDateUntil(e.target.value)}
              />
            </div>
            <input
              type="submit"
              className="fadeIn fourth"
              value=">> Lanjutkan <<"
            />
          </form>

          <div id="formFooter"></div>
        </div>
      </div>
    </div>
  );
};

export default NewTwitterPrediction;
