import React, { useState, useEffect } from "react";
// import { HariHari } from "../components/Data";
import LineChart from "../components/LineChart";
import TweetCard from "../components/TweetCard";
import * as FaIcons from "react-icons/fa";
import "./Report.css";
import axios from "axios";

function Report() {
  // Ambil data dummy
  let [countData, setCountData] = useState([]);
  let [lineData, setLineData] = useState([]);
  // let [hashtagData, setHashtagData] = useState([]);
  // let [mentionData, setMentionData] = useState([]);
  let [tweetData, setTweetData] = useState([]);
  console.log(tweetData)

  // The useEffect() hook fires any time that the component is rendered.
  // An empty array is passed as the second argument so that the effect only fires once.
  useEffect(() => {
    axios
      .get("http://be-sa.qoisoctava.com/data/twitter/all/count")
      .then((response) => {
        
        setCountData(response.data);
      });
    axios
      .get("http://be-sa.qoisoctava.com/data/twitter/all/summary")
      .then((response) => {
        
        setLineData(response.data);
      });
    axios.get("http://be-sa.qoisoctava.com/data/twitter/all").then((response) => {
      
      setTweetData(response.data);
    });
    // axios
    //   .get("http://localhost:3000/data/twitter/content/hashtag")
    //   .then((response) => {
    //     console.log(response.data);
    //     setHashtagData(response.data);
    //   });
    // axios
    //   .get("http://localhost:3000/data/twitter/content/mention")
    //   .then((response) => {
    //     console.log(response.data);
    //     setMentionData(response.data);
    //   });
  }, []);

  
  const LineData = {
    labels: lineData.map((data) => data.contentDate),
    datasets: [
      {
        label: "Positif",
        data: lineData.map((data) => data.positive),
        borderColor: ["rgba(13,110,253,1)"],
        backgroundColor: ["rgba(13,110,253,1)"],
      },
      {
        label: "Netral",
        data: lineData.map((data) => data.neutral),
        backgroundColor: ["rgba(255,193,7,1)"],
        borderColor: ["rgba(255,193,7,1)"],
      },
      {
        label: "Negatif",
        data: lineData.map((data) => data.negative),
        backgroundColor: ["rgba(220,53,69,1)"],
        borderColor: ["rgba(220,53,69,1)"],
      },
    ],
  };
  return (
    <div className="container">
      {/* Section Total */}
      <div className="row row-cols-sm-2 row-cols-xl-4 row my-2 ">
        <div className="col my-2">
          <div className="col align-self-center">
            <h1 className="fw-bold display-2 mt-2 text-black">{countData.map((data) => data.total)}</h1>
            <h1 className="display-6 text-black">TWEETS</h1>
          </div>
        </div>
        <div className="col my-2">
          <div className="card bg-primary ">
            <div className="col align-self-center">
              <h1 className="fw-bold display-2 mt-2 text-white">{countData.map((data) => data.positive)}</h1>
              <h1 className="display-6 text-white">POSITIF</h1>
            </div>
          </div>
        </div>
        <div className="col my-2">
          <div className="card bg-warning ">
            <div className="col align-self-center">
              <h1 className="fw-bold display-2 mt-2 text-white">{countData.map((data) => data.neutral)}</h1>
              <h1 className="display-6 text-white">NETRAL</h1>
            </div>
          </div>
        </div>
        <div className="col my-2">
          <div className="card bg-danger ">
            <div className="col align-self-center">
              <h1 className="fw-bold display-2 mt-2 text-white">{countData.map((data) => data.negative)}</h1>
              <h1 className="display-6 text-white">NEGATIF</h1>
            </div>
          </div>
        </div>
      </div>
      {/* Line Chart Section */}
      <div className="row d-flex justify-content-center  shadow rounded-2 bg-white my-2">
        <div className="col">
          <LineChart chartData={LineData} />
        </div>
      </div>
      <div className="row">{/* Space buat wordcloud kalau jadi */}</div>
      {/* Hashtag and Mention Section
      <div className="row justify-content-between">
        <div className="col shadow rounded-2 bg-white my-2 me-xl-2">
          <h5 className="my-2 m-title px-5 py-2 shadow-sm">
            <FaIcons.FaTwitter /> Sentiment by Hashtag
          </h5>
          <div className="tableFixHead mb-3 shadow-sm">
            <table id="content-table" class="table">
              <thead className="bg-light">
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Hashtag</th>
                  <th scope="col" className="text-center">
                    Positif
                  </th>
                  <th scope="col" className="text-center">
                    Netral
                  </th>
                  <th scope="col" className="text-center">
                    Negatif
                  </th>
                  <th scope="col" className="text-center">
                    Total
                  </th>
                </tr>
              </thead>
              <tbody className="tab-space">
                {hashtagData.map((data, index) => (
                  <tr key={data.id}>
                    <th scope="row">{index + 1}</th>
                    <td>{data.content}</td>
                    <td className="text-center">{data.positive}</td>
                    <td className="text-center">{data.neutral}</td>
                    <td className="text-center">{data.negative}</td>
                    <td className="text-center">{data.total}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col shadow rounded-2 bg-white my-2 ms-xl-2">
          <h5 className="my-2 m-title px-5 py-2 shadow-sm">
            <FaIcons.FaTwitter /> Sentiment by Mention
          </h5>
          <div className="tableFixHead mb-3 shadow-sm">
            <table class="table">
              <thead className="bg-light tab-space-head">
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Username</th>
                  <th scope="col" className="text-center">
                    Positif
                  </th>
                  <th scope="col" className="text-center">
                    Netral
                  </th>
                  <th scope="col" className="text-center">
                    Negatif
                  </th>
                  <th scope="col" className="text-center">
                    Total
                  </th>
                </tr>
              </thead>
              <tbody className="tab-space-body ">
                {mentionData.map((data, index) => (
                  <tr key={data.id}>
                    <th scope="row">{index + 1}</th>
                    <td>{data.content}</td>
                    <td className="text-center">{data.positive}</td>
                    <td className="text-center">{data.neutral}</td>
                    <td className="text-center">{data.negative}</td>
                    <td className="text-center">{data.total}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div> */}
      {/* Tweets Section */}
      <div className="row shadow rounded-2 bg-white my-2 ">
        <div className="row mt-2 ms-auto">
          <div className="h5 m-title px-5 py-2 shadow-sm">
            <FaIcons.FaTwitter /> Tweets
          </div>
        </div>
        <div className="row pb-2 ">
          <body className="overflow-scroll space ">
            <ul >
              <li className="list">
                {tweetData.map((data) => (
                  <TweetCard
                    username={data.username}
                    tweet={data.tweet}
                    date={data.content_date}
                    like={data.like_count}
                    reply={data.reply_count}
                    retweet={data.retweet_count}
                    sentiment={data.sentiment}
                  />
                ))}
              </li>
            </ul>
          </body>
        </div>
      </div>
    </div>
  );
}

export default Report;
