import React from 'react'

const Test = () => {
  return (
    <>
    <div className="row  d-flex justify-content-center">
        <div className="col">

    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScLmzUaaGCethpIcm7Q6tjix7DZOSJkswBslyLH1cKGd30i3A/viewform?embedded=true" width="1000" height="1896" frameborder="0" marginheight="0" marginwidth="0">Memuat…</iframe>
        </div>
    </div>
    
    </>
  )
}

export default Test 