import React, { useState } from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { Link } from "react-router-dom";
import "./Navbar.css";
import { IconContext } from "react-icons";

function Navbar() {
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);

  return (
    <>
      <div className="navbar">
        <div className="col-4">
          <Link to="#" className="menu-bars">
            <IconContext.Provider value={{ color: "#fff" }}>
              <FaIcons.FaBars onClick={showSidebar} />
            </IconContext.Provider>
          </Link>
        </div>
        {/* Navbar Title */}
        <div className="col-4 align-self-center text-white">
          <img src="/title.png" alt="" height={60} className="text-center display-5 m-0 p-0 d-none d-md-block"/>
          <p className="text-center dy-1 p-0 d-md-none d-block">
          <img src="/circles.png" alt="" height={50} className="me-1"/><b>SA</b>
          </p>
          
        </div>
        <div className="col-4"></div>
      </div>
      {/* Sidebar */}
      <nav className={sidebar ? "nav-menu shadow active" : "nav-menu"}>
        <ul className="nav-menu-items">
          <li className="navbar-toggle" onClick={showSidebar}>
            <IconContext.Provider value={{ color: "#fff" }}>
              <Link to="#" className="menu-bars">
                <AiIcons.AiOutlineClose />
              </Link>
            </IconContext.Provider>
          </li>
          {/* Beranda */}
          <li className="nav-text">
            <Link to="/">
              <AiIcons.AiFillHome />
              <span>Home</span>
            </Link>
          </li>

          {/* Menu Result */}
          <div className="accordion accordion-flush bg-transparent" id="accordion">
            <div className="accordion-item bg-transparent">
              <h2 className="accordion-header" id="flush-headingOne">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  <div className="nav-title">
                    <AiIcons.AiFillClockCircle />
                    <span>Results</span>
                  </div>
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                className="accordion-collapse collapse bg-transparent text-white"
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordion"
              >
                <div className="accordion-body">
                  <ul>
                    <li className="nav-text nav-text-2">
                      <Link to="/result/history/Twitter">
                        <AiIcons.AiOutlineTwitter />
                        <span>Twitter</span>
                      </Link>
                    </li>
                    <li className="nav-text nav-text-2">
                      <Link to="/result/history/youtube">
                        <AiIcons.AiFillYoutube />
                        <span>Youtube</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* Menu Predict */}
          <div className="accordion accordion-flush bg-transparent" id="accordion2">
            <div className="accordion-item bg-transparent">
              <h2 className="accordion-header" id="flush-headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTwo"
                  aria-expanded="false"
                  aria-controls="flush-collapseTwo"
                >
                  <div className="nav-title">
                    <FaIcons.FaRobot />
                    <span>Predict</span>
                  </div>
                </button>
              </h2>
              <div
                id="flush-collapseTwo"
                className="accordion-collapse collapse bg-transparent text-white"
                aria-labelledby="flush-headingTwo"
                data-bs-parent="#accordion2"
              >
                <div className="accordion-body">
                  <ul>
                    <li className="nav-text nav-text-2">
                      <Link to="/predict/twitter">
                        <AiIcons.AiFillTwitterCircle />
                        <span>Twitter</span>
                      </Link>
                    </li>
                    <li className="nav-text nav-text-2">
                      <Link to="/predict/youtube">
                        <AiIcons.AiOutlineYoutube />
                        <span>Youtube</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </ul>
      </nav>
    </>
  );
}

export default Navbar;
