import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
// import Footer from "./components/Footer";
import Home from "./pages/Home";
import TwitterHistory from "./pages/TwitterHistory";
import YoutubeHistory from "./pages/YoutubeHistory";
import Report from "./pages/Report";
import TwitterReportByHistory from "./pages/TwitterReportByHistory";
import YoutubeReportByHistory from "./pages/YoutubeReportByHistory";
import NewTwitterPrediction from "./pages/NewTwitterPrediction";
import NewYoutubePrediction from "./pages/NewYoutubePrediction";
import Test from "./pages/Test";
// import Result from "./pages/Result";



function App() {
  return (
    
    <BrowserRouter>
      <Navbar/>
      
      <div class="container">
      
        <Routes >
        {/* <Route path='/' element={<Home/>}/> */}
        <Route path='/' element={<Home/>}/>
        <Route path='/result/report/all' element={<Report/>}/>
        <Route path='/result/report/twitter/:id' element={<TwitterReportByHistory/>}/>
        <Route path='/result/report/youtube/:id' element={<YoutubeReportByHistory/>}/>
        <Route path='/result/history/twitter' element={<TwitterHistory/>}/>
        <Route path='/result/history/youtube' element={<YoutubeHistory/>}/>
        <Route path='/predict/twitter' element={<NewTwitterPrediction/>}/>
        <Route path='/predict/youtube' element={<NewYoutubePrediction/>}/>
        <Route path='/BetaTest' element={<Test/>}/>
      </Routes>
      </div>
      {/* <Footer/>
       */}
    </BrowserRouter>
    
  );
}

export default App;
