import React, { useState } from "react";
import "./PredictForm.css";
import axios from "axios";
import * as AiIcons from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const NewYoutubePrediction = () => {
  // let [postTopics, setPostTopics] = useState([]);
  // useEffect(() => {
  //   axios.get(`http://localhost:3000/data/topics`).then((response) => {
  //     setPostTopics(response.data);
  //   });
  // });

  const [id, setId] = useState("");
  const [topic] = "Politic";
  const navigate = useNavigate();

  const Predict = async (e) => {
    e.preventDefault();
    axios.post("https://api-sa.qoisoctava.com/youtube/prediction/", {
      id: id,
      topic: topic,
    });
    navigate("/result/history/youtube");
  };

  return (
    <div className="container my-auto ">
      <div className="wrapper-form fadeInDown">
        <div id="formContent">
          <div className="fadeIn first">
            <h1 className="pt-3">
              <AiIcons.AiOutlineYoutube />
            </h1>
            <h1 className="display-6">Buat Prediksi</h1>
          </div>

          <form onSubmit={Predict}>
            
            <div className="input-group mb-3 fadeIn second formWrapper">
              <span className="input-group-text ilink" id="basic-addon3">
               <b>https://www.youtube.com/watch?v=</b>
              </span>
              <input
                type="text"
                class="form-control Id"
                id="basic-url"
                aria-describedby="basic-addon3"
                placeholder="MasukkanID"
                value={id}
                onChange={(e) => setId(e.target.value)}
              />
            </div>
            {/* <div >
              <input
                type="text"
                className="fadeIn second"
                name="login"
                placeholder="Keyword Pencarian"
                value={id}
                onChange={(e) => setId(e.target.value)}
              />
            </div> */}
            {/* <div>
              <input
                type="text"
                id="password"
                className="fadeIn third"
                name="login"
                placeholder="Kategori Topik"
                list="topics"
                value={topic}
                onChange={(e) => setTopic(e.target.value)}
              />
              <datalist id="topics">
                {postTopics.map((data) => (
                  <option value={data.topic}>{data.topic}</option>
                ))}
              </datalist>
            </div> */}
            <input
              type="submit"
              className="fadeIn fourth"
              value=">> ANALISIS <<"
            />
          </form>

          <div id="formFooter"></div>
        </div>
      </div>
    </div>
  );
};

export default NewYoutubePrediction;
